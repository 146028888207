import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { IconCross } from '~/ui/kit/atoms/IconCross';

import { OnboardingStep } from './lib';
import * as NavButton from './ui/NavigationButton';
import * as S from './PageOnboarding.styled';

export type PageOnboardingProps = {
  className?: string;
  isOpen: boolean;
  id: string;
  isLoading?: boolean;
  welcomePopupInfo?: {
    width?: number;
    title: React.ReactNode;
    content: React.ReactNode;
    nextButtonContent?: React.ReactNode;
  };
  steps: (OnboardingStep & { title?: React.ReactNode })[];
  onComplete: VoidFunction;
  onCancel?: VoidFunction;
};

export const PageOnboardingProvider: FC<PropsWithChildren<PageOnboardingProps>> = ({
  onComplete: onCompleteProp,
  welcomePopupInfo,
  onCancel,
  isLoading,
  ...props
}) => {
  const [showWelcomePopup, setShowWelcomePopup] = useState(!!welcomePopupInfo);
  useEffect(() => {
    setShowWelcomePopup(!!welcomePopupInfo);
  }, [welcomePopupInfo]);

  const onboardingSteps: OnboardingStep[] = useMemo(() => {
    if (!props.isOpen || showWelcomePopup) {
      return [];
    }

    return props.steps.map((step) => {
      const highlightClassName = S.getEkoBorderRadiusCN(step.highlightBorderRadius || 'l');

      return {
        id: step.id,
        className: `.${step.className}`,
        position: step.position,
        highlightClassName,
        scrollTo: step.scrollTo,
        sectionWidth: step.sectionWidth,
        fillDefaultBackground: step.fillDefaultBackground,
        additionalLeftOffset: step.additionalLeftOffset,
        elementToGetWidthFromClassName: step.elementToGetWidthFromClassName,
        content: (
          <>
            {(step.title || onCancel) && (
              <S.StepTitleContainer>
                {step.title && <S.StepTitle>{step.title}</S.StepTitle>}
                {onCancel && (
                  <>
                    <div />
                    <S.Cancel onClick={onCancel}>
                      <IconCross />
                    </S.Cancel>
                  </>
                )}
              </S.StepTitleContainer>
            )}
            <S.StepContent>{step.content}</S.StepContent>
          </>
        ),
      };
    });
  }, [props.steps, showWelcomePopup, props.isOpen, onCancel]);

  const closeWelcomePopup = useCallback(() => {
    setShowWelcomePopup(false);
  }, []);

  const openWelcomePopup = useCallback(() => {
    setShowWelcomePopup(true);
  }, []);

  const onComplete = useCallback(() => {
    onCompleteProp();
  }, [onCompleteProp]);

  const onExitSteps = useCallback(() => {
    if (welcomePopupInfo) {
      openWelcomePopup();
    } else {
      closeWelcomePopup();
      void (onCancel && onCancel());
    }
  }, [closeWelcomePopup, openWelcomePopup, welcomePopupInfo, onCancel]);

  const welcomePopup = useMemo(() => {
    if (!welcomePopupInfo || !props.isOpen) {
      return null;
    }

    return (
      <S.WelcomePopup
        $width={welcomePopupInfo.width}
        key="onboarding welcome popup"
        onClickNo={() => null}
        onClickYes={closeWelcomePopup}
        yesBtnText={
          welcomePopupInfo.nextButtonContent === undefined ? 'Get started' : welcomePopupInfo.nextButtonContent
        }
        isVisible={showWelcomePopup}
        isLoading={false}
        title={<S.WelcomeTitle>{welcomePopupInfo.title}</S.WelcomeTitle>}
      >
        <S.WelcomeContent>{welcomePopupInfo.content}</S.WelcomeContent>
      </S.WelcomePopup>
    );
  }, [welcomePopupInfo, closeWelcomePopup, showWelcomePopup, props.isOpen]);

  if (!onboardingSteps.length) {
    return (
      <>
        {welcomePopup}
        {props.children}
      </>
    );
  }

  return (
    <>
      {welcomePopup}
      <S.Container
        isLoading={isLoading}
        className={props.className}
        NextButton={NavButton.NextBtn}
        PrevButton={NavButton.PrevBtn}
        onExitSteps={onExitSteps}
        steps={onboardingSteps}
        visible={props.isOpen}
        onComplete={onComplete}
      >
        {props.children}
      </S.Container>
    </>
  );
};
